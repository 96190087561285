import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo() {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center md:py-2 py-10">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src="https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/videos%2Fwelding%20stock.mp4?alt=media&token=2a001dbb-36f3-498f-844e-6a3864979da4"
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[0px] md:bottom-[100px] lg:bottom-[80px] md:text-start text-center text-white">
                    <div className="w-full p-4">
                        <h1 className="text-[28px] md:text-[40px] lg:text-[60px] md:text-start">{rpdata?.dbSlogan?.[1].slogan}</h1>
                        <p className="md:text-start">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default HeroVideo;